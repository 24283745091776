@import '~@material/react-text-field/index.scss';
@import '~@material/react-top-app-bar/index.scss';
@import '~@material/react-material-icon/index.scss';

.App {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: white;
}

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.Dual-App {
    display: inline;
    position: relative;
    min-height: 100vh;
}

.dual-app-background {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    width: 100%;
    position: absolute;
}

.dual-app-background-child {
    flex: 1;
    //height: 100%;
}

.Search {
    margin: 16px;
}

button {
    margin: 16px 0 0 16px;
    align-self: flex-start;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.mdc-icon-button {
    color: #fff;
}

.back-button {
    position: absolute;
    color: #000;
}

.top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mdc-top-app-bar {
    @include mdc-top-app-bar-fill-color-accessible(#fff);
    @include mdc-top-app-bar-icon-ink-color(#000);
}

.search-container {
    flex: 1;
}

.dual-button {
    padding: 0 16px;
}

.mdc-text-field {
    margin-right: 16px;
    @include mdc-text-field-caret-color(#000);
    @include mdc-text-field-fill-color(#00000000);
    @include mdc-text-field-line-ripple-color(#00000000);
    @include mdc-text-field-bottom-line-color(#00000000);
    border-bottom-color: transparent;
}

.mdc-text-field--focused {
    @include mdc-text-field-label-color(#000);
    @include mdc-text-field-fill-color(#00000000);
}

.mdc-top-app-bar__action-item {
    margin-right: 16px;
}

.detail-title {
    z-index: 10;
    font-weight: bold;
    font-size: 20pt;
    text-align: center;
    margin-top: 12px;
    color: black;
    position: fixed;
    align-self: center;
}