.Type {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-weight: bold;
    font-size: 20pt;
    text-align: center;
    color: black;
}

.extraNotes {
    font-size: 12pt;
    text-align: center;
    padding: 16px;
    color: black;
}

.sectionHeader {
    text-align: center;
    color: black;
}

.section {
    margin-top: 24px;
}